<template>
  <div id="components">
    <Music :musicSrc="'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1385972968%3Fsecret_token%3Ds-gMG5vn7O9jk&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true'" />
  </div>
</template>

<script>

import Music from "@/components/Music";

export default {
  name: 'Music-View',
  components: {Music},
}
</script>
